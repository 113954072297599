(function () {
	'use strict';
	angular
		.module('sources')
		.factory('manageSchedules', [
			'$rootScope',
			'$timeout',
			'seedcodeCalendar',
			'utilities',
			'dataStore',
			'manageSettings',
			'manageCalendarActions',
			manageSchedules,
		]);

	function manageSchedules(
		$rootScope,
		$timeout,
		seedcodeCalendar,
		utilities,
		dataStore,
		manageSettings,
		manageCalendarActions
	) {
		var selectQueue = 0;
		return {
			selectSchedule: selectSchedule,
			saveSelected: saveSelected,
			updateSchedule: updateSchedule,
			restoreSources: restoreSources,
			toggleCalendarFolder: toggleCalendarFolder,
			restoreSessionCallapseState: restoreSessionCallapseState,
			createSourceFolders: createSourceFolders,
			initializeSourceFolders: initializeSourceFolders,
		};

		function selectSchedule(
			schedule,
			enforceSelectQueue,
			folderSelection,
			scheduleChangeCount,
			changedSchedules
		) {
			var config = seedcodeCalendar.get('config');
			var element = seedcodeCalendar.get('element');
			var schedulesForSelection = [];
			var schedules;

			if (!changedSchedules) {
				changedSchedules = [];
			}

			if (schedule.isFolder) {
				schedules = seedcodeCalendar.get('schedules');
				for (var property in config.activeSourceTypes) {
					if (
						config.activeSourceTypes[property].id ===
						schedule.sourceTypeID
					) {
						schedule.status.selected = !schedule.status.selected; //config.activeSourceTypes[property].status.selected;
						for (var i = 0; i < schedules.length; i++) {
							if (
								config.activeSourceTypes[property].id ===
									schedules[i].sourceTypeID &&
								schedules[i].status.selected !==
									schedule.status.selected
							) {
								schedulesForSelection.push(schedules[i]);
							}
						}
						break;
					}
				}

				scheduleChangeCount = schedulesForSelection.length;

				//Loop schedules for selection so we know how many to enable
				for (var i = 0; i < schedulesForSelection.length; i++) {
					scheduleChangeCount--;
					selectSchedule(
						schedulesForSelection[i],
						false,
						true,
						scheduleChangeCount,
						changedSchedules
					);
				}

				$rootScope.$broadcast('schedules');
				return;
			}

			changedSchedules.push(schedule);

			if (!scheduleChangeCount) {
				seedcodeCalendar.init(
					'changedSchedules',
					changedSchedules,
					true
				);
			}

			if (!schedule.status.selected) {
				schedule.status.selected = true;
				schedule.status.changed = enforceSelectQueue ? false : true;
				schedule.source.enabled = true;
				element.fullCalendar('addEventSource', schedule.source);
			} else {
				schedule.status.selected = false;
				schedule.source.enabled = false;
				element.fullCalendar(
					'removeEventSource',
					schedule.source,
					schedule.id
				);
				removeOrphans(schedule, scheduleChangeCount);
			}
			saveSelected(null, null, schedule, scheduleChangeCount);

			if (!folderSelection) {
				$rootScope.$broadcast('schedules', true);
			}

			function removeOrphans(schedule, scheduleChangeCount) {
				var element = seedcodeCalendar.get('element');
				var events = element.fullCalendar('clientEvents');
				var eventsToRemove = [];
				for (var i = 0; i < events.length; i++) {
					if (
						events[i].schedule.name === schedule.name &&
						events[i].schedule.id === schedule.id
					) {
						eventsToRemove.push(events[i]);
					}
				}

				// Pass array of events to be removed
				element.fullCalendar('removeEvents', eventsToRemove);
			}
		}

		function saveSelected(
			initialLoad,
			preventSaveState,
			schedule,
			scheduleChangeCount
		) {
			var calendarActions = seedcodeCalendar.get('calendarActions');
			var schedules = seedcodeCalendar.get('schedules');
			var saveOutput = [];
			var sourceOutput = [];
			var outputString;

			for (var i = 0; i < schedules.length; i++) {
				if (schedules[i].status.selected) {
					sourceOutput.push(schedules[i].source);
				} else {
					saveOutput.push(schedules[i].id + schedules[i].name);
				}
			}
			if (!initialLoad) {
				//Broadcast that our sources have changed - ToDo: we could rely on the schedule broadcast and pick sources out of schedules
				$rootScope.$broadcast('eventSources');
			}
			if (!preventSaveState) {
				outputString = JSON.stringify(saveOutput);
				dataStore.saveState('selectedSchedules', outputString);
			}
			//Run on load calendar actions
			if (calendarActions && calendarActions.afterSourceSelection) {
				//Run load actions
				var actionData = {
					item: schedule,
					isLast:
						!scheduleChangeCount || scheduleChangeCount === 0
							? true
							: false,
				};
				manageCalendarActions.runCalendarActions(
					calendarActions.afterSourceSelection,
					null,
					actionData
				);
			}
		}

		function updateSchedule(operation, editObj, successCallback) {
			var schedules = seedcodeCalendar.get('schedules');
			var schedule;
			var scheduleChanges;
			var processCount = 0;

			//Get the schedule from our list of schedules
			for (var i = 0; i < schedules.length; i++) {
				if (schedules[i].id === editObj.id) {
					schedule = schedules[i];
					break;
				}
			}

			// scheduleChanges = updateSource(schedule, operation, editObj, successCallback);
			scheduleChanges = updateSource(schedule, operation, editObj, null);

			//Force the view to show the new changes
			//seedcodeCalendar.init('schedules', schedules);

			//Write our changes to the backend
			if (scheduleChanges.hasOwnProperty('defaultResource')) {
				processCount++;
				//Write changed default resource to user settings
				defaultResourceChanged(editObj, function () {
					runCallback();
				});
			}
			if (
				scheduleChanges.hasOwnProperty('userDefaultResource') ||
				scheduleChanges.hasOwnProperty('enableUserDefaultResource')
			) {
				processCount++;
				//Write changed default resource to user settings
				userDefaultResourceChanged(schedule, editObj, function () {
					runCallback();
				});
			}
			if (
				scheduleChanges.hasOwnProperty('isPrimary') ||
				scheduleChanges.hasOwnProperty('primaryCalendarOverride')
			) {
				//Write our changed primary calendar selction back to user settings
				primaryChanged(editObj, function () {
					runCallback();
				});
			}
			if (scheduleChanges.hasOwnProperty('name')) {
				processCount++;
				//Write our changed primary calendar selction back to user settings
				schedule.source.changeScheduleName(
					editObj.name,
					function (result) {
						//Run a check for error and revert if there is an error
						if (result.error) {
							//Run revert process
							alert('error');
						}
						runCallback();
					}
				);
			}
			if (scheduleChanges.hasOwnProperty('backgroundColor')) {
				processCount++;
				//Write our changed primary calendar selction back to user settings
				schedule.source.changeScheduleColor(
					editObj.backgroundColor,
					function (result) {
						//Run a check for error and revert if there is an error
						if (result.error) {
							//Run revert process
							alert('error');
						}
						runCallback();
					}
				);
			}

			//Run our callback in case no other routines were needed
			processCount++;
			runCallback();

			function runCallback() {
				processCount--;
				if (processCount <= 0) {
					successCallback();
				}
			}
		}

		function revertChange(schedule, revertObj) {
			for (var property in revertObj) {
				schedule[property] = revertObj[property];
			}
		}

		function updateSource(schedule, operation, editObj, successCallback) {
			var sourceChanges = {};
			//operation options: 'create', 'edit', 'delete'
			if (!editObj.id && operation !== 'delete') {
				return;
			}
			if (operation === 'edit' && !editObj.id) {
				//sourceChanges = {id: utilities.generateUID(), name: editObj.editName, shortName: editObj.shortName, color: editObj.color};
				//operation = "create";
				//scheduleList.push({id: filterChanges.id, name: filterChanges.name, shortName: filterChanges.shortName, color: filterChanges.color, status: {selected: false}});
			} else {
				if (operation === 'delete') {
					// statuses.splice(i , 1);
					sourceChanges = {id: schedule.id, operation: 'delete'};
				} else if (operation === 'edit') {
					for (var property in editObj) {
						if (schedule[property] !== editObj[property]) {
							//Check if we are trying to compare boolean against non boolean (undefined for example)
							if (
								editObj[property] === false &&
								schedule[property] !== false &&
								schedule[property] !== true
							) {
								continue;
							}
							sourceChanges[property] = editObj[property];
							schedule[property] = editObj[property];
						}
					}
				}
			}

			return sourceChanges;
		}

		function primaryChanged(editObj, callback) {
			var primaryCalendar = editObj.isPrimary ? editObj.id : null;
			manageSettings.setUserSettings(
				null,
				{primaryCalendar: primaryCalendar},
				function () {
					applyPrimarySelection(editObj, primaryCalendar);
					if (callback) {
						callback();
					}
				}
			);
		}

		function applyPrimarySelection(editObj, primaryCalendar) {
			var config = seedcodeCalendar.get('config');
			var schedules = seedcodeCalendar.get('schedules');
			var primaryCalendarOverride;
			if (config.admin) {
				// Get override data if any
				primaryCalendarOverride = editObj.primaryCalendarOverride
					? editObj.id
					: null;
				// Set primary override data
				manageSettings.setGroupSettings(
					'primaryCalendarOverride',
					primaryCalendarOverride,
					false,
					function () {
						var settings = seedcodeCalendar.get('settings');
						config.primaryCalendarOverride =
							primaryCalendarOverride;
					}
				);
			}
			//Set config value
			config.primaryCalendar = editObj.id;
			primaryCalendar = primaryCalendarOverride || primaryCalendar;

			//Reset schedule primary selection
			for (var i = 0; i < schedules.length; i++) {
				schedules[i].isPrimary =
					primaryCalendar && schedules[i].id === editObj.id
						? true
						: false;
			}
		}

		function userDefaultResourceChanged(schedule, editObj, callback) {
			const output = {};
			// Get if user default should be enabled or not
			const enableDefaultResource =
				editObj.enableUserDefaultResource ||
				(!editObj.hasOwnProperty('enableUserDefaultResource') &&
					schedule.enableUserDefaultResource);

			const outputKey = utilities.stringToID(schedule.id);

			if (enableDefaultResource) {
				output[outputKey] = {
					enabled: true,
					resource: editObj.userDefaultResource,
				};
			} else {
				output[outputKey] = null;
			}

			manageSettings.setUserSettings(
				'defaultResources',
				output,
				function () {
					applyResourceSelection(
						editObj.id,
						enableDefaultResource
							? editObj.userDefaultResource
							: '',
						'userDefaultResource'
					);
					applyResourceSelection(
						editObj.id,
						enableDefaultResource
							? editObj.userDefaultResource
							: schedule.originalDefaultResource,
						'defaultResource'
					);
					if (callback) {
						callback();
					}
				}
			);
		}

		function defaultResourceChanged(editObj, callback) {
			var defaultResourceData = {};
			var key = utilities.stringToID(editObj.id);
			defaultResourceData[key] = editObj.defaultResource;
			manageSettings.setGroupSettings(
				'defaultResources',
				defaultResourceData,
				true,
				function () {
					applyResourceSelection(
						editObj.id,
						editObj.defaultResource,
						'defaultResource',
						callback
					);
				}
			);
		}

		function applyResourceSelection(
			scheduleID,
			newResourceID,
			destinationProperty,
			callback
		) {
			var schedules = seedcodeCalendar.get('schedules');
			var resources = seedcodeCalendar.get('resources');
			var resource;
			var i;
			for (i = 0; i < resources.length; i++) {
				if (resources[i].id === newResourceID) {
					resource = resources[i];
					break;
				}
			}
			for (i = 0; i < schedules.length; i++) {
				if (schedules[i].id === scheduleID) {
					//Create object with specific properties so we don't get unintended angular byproducts like $$hashKey
					schedules[i][destinationProperty] = resource
						? {
								id: resource.id,
								name: resource.name,
						  }
						: null;
					break;
				}
			}

			if (callback) {
				callback();
			}
		}

		function restoreSources(defaultItems) {
			var resetSources = defaultItems && defaultItems[0] === 'null';
			var schedules = seedcodeCalendar.get('schedules');
			var added;

			for (var i = 0; i < schedules.length; i++) {
				added = false;
				for (var ii = 0; ii < defaultItems.length; ii++) {
					if (defaultItems[ii] === schedules[i].name) {
						if (!schedules[i].status.selected) {
							selectSchedule(schedules[i]);
						}
						added = true;
					}
				}
				if (resetSources && !added && schedules[i].status.selected) {
					selectSchedule(schedules[i]);
				}
			}
		}

		function toggleCalendarFolder(scheduleItem, type) {
			var schedules = seedcodeCalendar.get('schedules');
			var activeSourceTypes =
				seedcodeCalendar.get('config').activeSourceTypes;
			var sourceTemplates = seedcodeCalendar.get('sourceTemplates');
			var collapsed;

			collapsed = !scheduleItem.status.collapsed;

			for (var i = 0; i < sourceTemplates.length; i++) {
				if (sourceTemplates[i].id === scheduleItem.sourceTypeID) {
					if (!sourceTemplates[i].status) {
						sourceTemplates[i].status = {};
					}
					sourceTemplates[i].status.collapsed = collapsed;
				}
			}

			scheduleItem.status.collapsed = collapsed;

			for (var i = 0; i < schedules.length; i++) {
				if (schedules[i].sourceTypeID === scheduleItem.sourceTypeID) {
					schedules[i].status.collapsed = collapsed;
				}
			}

			//Update our filter saved state
			updateScheduleFolderSavedState(sourceTemplates, type);
		}

		function updateScheduleFolderSavedState(sourceTemplates, type) {
			var output = [];

			for (var i = 0; i < sourceTemplates.length; i++) {
				if (
					sourceTemplates[i].status &&
					sourceTemplates[i].status.collapsed
				) {
					output.push(sourceTemplates[i].id);
				}
			}
			dataStore.saveState('sourceCollapsed', JSON.stringify(output));
		}

		function restoreSessionCallapseState(
			sourceTemplates,
			preCollapseState
		) {
			var stateIdentifier = 'source' + 'Collapsed';

			var savedState;

			if (preCollapseState) {
				savedState = preCollapseState;
			} else {
				savedState = JSON.parse(dataStore.getState(stateIdentifier));
			}
			if (!savedState) {
				return;
			}

			for (var i = 0; i < savedState.length; i++) {
				for (var ii = 0; ii < sourceTemplates.length; ii++) {
					if (savedState[i] === sourceTemplates[ii].id) {
						if (!sourceTemplates[ii].status) {
							sourceTemplates[ii].status = {};
						}
						sourceTemplates[ii].status.collapsed = true;
					}
				}
			}
		}

		function initializeSourceFolders(sourceTemplates) {
			restoreSessionCallapseState(sourceTemplates);
		}

		function createSourceFolders(schedules) {
			var output = [];
			var sourceTypeObject;
			var config = seedcodeCalendar.get('config');
			var sourceTemplates = seedcodeCalendar.get('sourceTemplates');
			var sources = seedcodeCalendar.get('sources');
			var template;
			var preventSourceType;
			var enabledAuthSourceTypeMatrix = {};
			var allSelected = false;
			var hasSelected = false;

			if (!schedules) {
				return output;
			}

			for (var i = 0; i < sourceTemplates.length; i++) {
				if (!sourceTemplates[i].status.enabled) {
					continue;
				}

				allSelected = true;
				hasSelected = false;

				sourceTypeObject = {};
				sourceTypeObject.status = {
					collapsed:
						sourceTemplates[i].status &&
						sourceTemplates[i].status.collapsed
							? true
							: false,
					selected: true,
					children: 0,
				};

				sourceTypeObject.isFolder = true;
				sourceTypeObject.id = sourceTemplates[i].id;
				sourceTypeObject.name = sourceTemplates[i].name;
				sourceTypeObject.sourceTypeID = sourceTemplates[i].id;
				sourceTypeObject.sourceTemplate = sourceTemplates[i];

				if (sourceTemplates[i].showAuthButton) {
					preventSourceType = true;
					for (var ii = 0; ii < sources.length; ii++) {
						if (sourceTypeObject.id === sources[ii].sourceTypeID) {
							preventSourceType = null;
							break;
						}
					}

					// If the source has an auth button we still want to show it
					// even with no source record
					if (preventSourceType) {
						sourceTypeObject.noSource = true;
						sourceTemplates[i].status.authed = false;
						preventSourceType = null;
					}
				}

				if (preventSourceType) {
					preventSourceType = null;
					continue;
				}

				output.push(sourceTypeObject);

				if (enabledAuthSourceTypeMatrix[sourceTypeObject.id]) {
					output.push(
						enabledAuthSourceTypeMatrix[sourceTypeObject.id]
					);
				}

				for (var iii = 0; iii < schedules.length; iii++) {
					if (schedules[iii].sourceTypeID === sourceTypeObject.id) {
						schedules[iii].status.collapsed =
							sourceTypeObject.status.collapsed;
						if (!schedules[iii].status.selected) {
							sourceTypeObject.status.selected = false;
							allSelected = false;
						} else {
							sourceTypeObject.status.children++;
							hasSelected = true;
						}
						output.push(schedules[iii]);
					}
				}

				sourceTypeObject.status.hasSelected = hasSelected;
				sourceTypeObject.status.allSelected = allSelected;
			}

			return output;
		}
	}
})();
